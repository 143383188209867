.paginationBox {
    display: flex;
    justify-content: center;
    margin: 6vmax;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    padding: 0;
  }
  
  .page-item {
    background-color: rgb(255, 255, 255);
    list-style: none;
    border: 1px solid rgba(0, 0, 0, 0.178);
    padding: 1vmax 1.5vmax;
    transition: all 0.3s;
    cursor: pointer;
  }
  .page-item:first-child {
    border-radius: 5px 0 0 5px;
  }
  
  .page-item:last-child {
    border-radius: 0 5px 5px 0;
  }
  .page-link {
    text-decoration: none;
    font: 300 0.7vmax "Roboto";
    color: rgb(80, 80, 80);
    transition: all 0.3s;
  }
  
  .page-item:hover {
    background-color: rgb(230, 230, 230);
  }
  
  .page-item:hover .page-link {
    color: rgb(0, 0, 0);
  }
  
  .pageItemActive {
    background-color: rgba(38, 198, 30, 0.902);
  }
  
  .pageLinkActive {
    color: white;
  }

  @media screen and (max-width: 600px) {  
    .page-link {
      font: 300 1.7vmax "Roboto";
    }
   
  }