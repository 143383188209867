.top {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
}

.header {
    background-color: #5EC198;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    font-family: Poppins,sans-serif;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

h1 {
    font-size: 32px;
    margin-bottom: 20px;
}

.sub-heading {
    font-size: 24px;
    margin-top: 30px;
}

.sub-heading + p {
    margin-top: 10px;
}

.card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.arrow1 {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #5EC198;
    margin: 0 auto;
}